body {
	background-color: #000;
	font-family: Arsenal;
}
.hexagon-wrapper{
  opacity: 1;
  transition: all 1s ease-out;
}


.gallery-headline-text {
  width:40%;
  margin-left:30%;
  align-items:'center';
  color:white;
  font-size:25;
  display: flex;
	align-items: center;
  flex:1;
  justify-content: center;
}
.gallery-headline-char-wrapper {
  float:left;
  font-family: 'Oswald', sans-serif;
  font-size: 50px;
  font-weight: bold;
  margin:0;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #d1a24d;
  text-shadow: 3px 3px rgba(0,0,0,1);
  animation: animation linear 2s infinite;
  opacity: 1;
}
.gallery-headline-border-left {
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #d1a24d, #fff);
}
.gallery-headline-border-right {
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #d1a24d, #fff);
}
.gallery-big-picture-wrapper {
	z-index: 10000;
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  --angle: 90deg;
  border-image: linear-gradient(var(--angle), #d1a24d, #fff) 1;
  opacity:1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: 0.5s ease-in-out;
}
.hexagoncomb {
	max-width: 1500px;
	margin: 70px auto;
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	padding-bottom: 5.5%;
}

.hexagoncomb li {
	width: 10%;
	position: relative;
	visibility: hidden;
}

.hexagoncomb li:after {
	content: '';
	display: block;
	padding-bottom: 86.6%;
}

.cell {
	position: absolute;
	width: 96%;
	margin: 0 2%;
	padding-bottom: 110.85%;
	transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
	overflow: hidden;
}

.cell * {
	margin: 0;
	padding: 0;
	position: absolute;
	visibility: visible;
}

.hexagoncomb li a {
	display: block;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
	overflow: hidden;
}

.hexagoncomb li img {
	left: -100%;
	right: -100%;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	transform: rotate3d(0, 0, 0, 0deg);
}

.hexagoncomb li .hexagallerytitle,
.hexagoncomb li .text {
	width: 100%;
	padding: 5%;
	display: block;
	box-sizing: border-box;
	background-color: rgba(209, 162, 77, 0.7);
	color: #fff;
	text-align: center;
	transition: transform .2s ease-out;
  opacity: .3s ease-out;
}

.hexagoncomb li .hexagallerytitle {
	bottom: 50%;
	padding-top: 50%;
	font-size: 1.5em;
	z-index: 1;
	transform: translate3d(0, -100%, 0);
}

.hexagoncomb li .hexagallerytitle:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 45%;
	width: 10%;
	border-bottom: 1px solid #fff;
}

.hexagoncomb li .text {
	top: 50%;
	padding-bottom: 50%;
	transform: translate3d(0, 100%, 0);
}

.hexagoncomb li a:hover .hexagallerytitle,
.hexagoncomb li a:focus .hexagallerytitle,
.hexagoncomb li a:hover .text,
.hexagoncomb li a:focus .text {
	transform: translate3d(0, 0, 0);
}

.gallery-headline-char-wrapper:nth-child(1){
  animation-delay: 0.9s;
}
.gallery-headline-char-wrapper:nth-child(2){
  animation-delay: 1.1s;
}
.gallery-headline-char-wrapper:nth-child(3){
  animation-delay: 1.3s;
}
.gallery-headline-char-wrapper:nth-child(4){
  animation-delay: 1.5s;
}
.gallery-headline-char-wrapper:nth-child(5){
  animation-delay: 1.7s;
}
.gallery-headline-char-wrapper:nth-child(6){
  animation-delay: 1.9s;
}
.gallery-headline-char-wrapper:nth-child(7){
  animation-delay: 2.1s;
}
.gallery-headline-char-wrapper:nth-child(8){
  animation-delay: 2.3s;
}
.gallery-headline-char-wrapper:nth-child(9){
  animation-delay: 2.5s;
}
.gallery-headline-char-wrapper:nth-child(10){
  animation-delay: 2.7s;
}
.gallery-headline-char-wrapper:nth-child(11){
  animation-delay: 2.9s;
}
.gallery-headline-char-wrapper:nth-child(12){
  animation-delay: 3.1s;
}
.gallery-headline-char-wrapper:nth-child(13){
  animation-delay: 3.3s;
}
.gallery-headline-char-wrapper:nth-child(14){
  animation-delay: 3.5s;
}

@media screen and (min-width:1001px) {
	.gallery-big-picture-wrapper {
		left: 30%;
	  width:40%;
	}
  .hexagoncomb li:nth-child(11), .hexagoncomb li:nth-child(30),.hexagoncomb li:nth-child(49) {
  	margin-left: 5%;
  }
}
@media screen and (max-width: 1000px) {
	.gallery-big-picture-wrapper {
		left: 5%;
		width:90%;
	}
  .hexagoncomb li {
  	width: 25%;
  	position: relative;
  	visibility: hidden;
  }
  .hexagoncomb li:nth-child(5),.hexagoncomb li:nth-child(12),.hexagoncomb li:nth-child(19),.hexagoncomb li:nth-child(26),.hexagoncomb li:nth-child(33),.hexagoncomb li:nth-child(40),
	.hexagoncomb li:nth-child(47),.hexagoncomb li:nth-child(54) {
  	margin-left: 12.5%;
  }
  .gallery-headline-char-wrapper {
    font-size: 35px;
  }
  .gallery-headline-border-right {
    margin-top: 5%;
  }
  .gallery-headline-border-left {
    margin-bottom: 5%;
  }
}
