.about-we {
	display: flex;
  float:left;
  transition: all .5s ease-out;

}
.about-we-wrapper {
  float:left;
  /* font-size: calc(10vw); */
  font-family: 'Oswald', sans-serif;
  font-size: 200px;
  line-height: 190px;
  font-weight: bold;
  margin:0;
  margin-right: 20px;
  margin-bottom: 20px;
  color: #d1a24d;
  text-shadow: 3px 3px rgba(0,0,0,1);
  animation: animation linear 2s infinite;
  opacity: 1;

}
.about-content {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  line-height: 35px;
  text-align: justify;
  word-wrap: break-word;
  color: #fff;
  transition: all 1s ease-out;
  opacity: 1;
}
.about-left-side-wrapper {
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  --angle: 0deg;
  border-image: linear-gradient(var(--angle), #d1a24d, #fff) 1;
  animation: 2s rotateBorder linear infinite;
  width: 250px;
  min-width: 250px;
  margin: auto;
  height: 400px;
  transform: rotateY(0deg);
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transition: all 1s ease-out;
  perspective: 1000px;
  opacity:1;
}
.about-left-side-wrapper-before{
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  --angle: 0deg;
  border-image: linear-gradient(var(--angle), #d1a24d, #fff) 1;
  animation: 2s rotateBorder linear infinite;
  width: 250px;
  min-width: 250px;

  margin: auto;
  height: 400px;
  transform: rotateY(90deg);
  opacity:0;
}
.about-center-side-wrapper {
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  --angle: 0deg;
  border-image: linear-gradient(var(--angle), #d1a24d, #fff) 1;
  animation: 2s rotateBorder linear infinite;
  width: 250px;
  min-width: 250px;

  height: 400px;
  transform: rotateY(0deg);
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transition: all 1s ease-out;
  perspective: 1000px;
  opacity:1;
  transition-delay: 400ms;
}
.about-center-side-wrapper-before{
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  --angle: 0deg;
  border-image: linear-gradient(var(--angle), #d1a24d, #fff) 1;
  animation: 2s rotateBorder linear infinite;
  width: 250px;
  min-width: 250px;

  height: 400px;
  transform: rotateY(90deg);
  opacity:0;
}
.about-right-side-wrapper {
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  --angle: 0deg;
  border-image: linear-gradient(var(--angle), #d1a24d, #fff) 1;
  animation: 2s rotateBorder linear infinite;
  width: 250px;
  min-width: 250px;
  height: 400px;
  transform: rotateY(0deg);
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transition: all 1s ease-out;
  perspective: 1000px;
  opacity:1;
  margin: auto;
  transition-delay: 400ms;
}
.about-right-side-wrapper-before{
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  --angle: 0deg;
  border-image: linear-gradient(var(--angle), #d1a24d, #fff) 1;
  animation: 2s rotateBorder linear infinite;
  width: 250px;
  margin-left:12%;
  height: 400px;
  transform: rotateY(90deg);
  opacity:0;
}
.about-textimage-common-style {
  margin:1%;
  border: 5px solid;
  border-image-source: linear-gradient(to left, #d1a24d, #fff);
  border-image: linear-gradient(to left, #d1a24d, #fff) 1;
  width:calc(97% - 7px);
  height:48%;
}
.about-maincat-wrapper{
  position:relative;
  overflow: hidden;
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #d1a24d, #fff);
  --angle: 45deg;
  border-image: linear-gradient(var(--angle), #d1a24d, #fff) 1;
  overflow: hidden;
  box-shadow: 0 5px 15px 0px rgba(255,255,255,0.6);

}
.about-maincat-wrapper:hover .about-maincat-wrapper-content {
  bottom:0%;
}
.about-maincat-wrapper:hover .about-maincat-wrapper-image {
  transform: scale(1.5);
}
.about-maincat-wrapper-image {
  width:100%;
  height:100%;
  background: url('../img/gallerybigptcs/cat5.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.4s ease;
  transform-origin: 70% 0%;
}
.about-maincat-wrapper-content {

  position:absolute;
  bottom:-40%;
  left:0;
  width:100%;
  height:40%;
  /* background-color: rgba(209, 162, 77 , 0.7); */
  transition: all 0.4s ease-out;
  backdrop-filter: blur(15px);
  box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(209, 162, 77, 0.1);
  border-radius: 15px;

}
.about-right-cat-wrapper {
  margin-left:10%;
  flex: 1;
  display:flex;
  flex-direction:row;
  background: linear-gradient(to right ,#fff , #d1a24d, #000 );
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  --angle: 270deg;
  border-image: linear-gradient(var(--angle), #000 ,  #000 , #d1a24d, #fff) 1;
}
.about-left-cat-wrapper {
  flex: 1;
  display:flex;
  flex-direction:row;
  margin-right:10%;
  background: linear-gradient(to left ,#fff , #d1a24d, #000 );
  border: 5px solid;
  border-image-slice: 1;
  --angle: 90deg;
  border-image: linear-gradient(var(--angle), #000 ,  #000 , #d1a24d, #fff) 1;
}
.about-cat-image-left {
  flex:0.8;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  --angle: 270deg;
  border-image: linear-gradient(var(--angle), #000 ,  #000 , #d1a24d, #fff) 1;
  box-shadow: -6px 0px 15px -4px rgba(0,0,0,0.8);

}
.about-cat-image-right {
  flex:0.8;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  --angle: 90deg;
  border-image: linear-gradient(var(--angle), #000 ,  #000 , #d1a24d, #fff) 1;
  box-shadow: 6px 0px 15px -4px rgba(0,0,0,0.8);

}
.about-left-side-image {
  background: url('../img/gallerybigptcs/cattery.jpg');
  background-position: 0 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.about-center-side-image {
  background: url('../img/wcf.jpeg');
  background-position: 0 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.about-right-side-image {
  background: url('../img/pets.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.about-textimage-content {
  height:48%;
  margin:1%;
  color:white;
  align-items: center;
  text-align: center;
}
.about-span-left {
  background: linear-gradient(to right ,#d1a24d, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-span-right {
  background: linear-gradient(to left ,#d1a24d, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-image-text-wrapper {
  min-height:200px;
  width:100%;
  min-width: 100%;
  margin-bottom:5%;
  align-items: center;
}
.about-we-wrapper:nth-child(1){
  animation-delay: 0.9s;
}
.about-we-wrapper:nth-child(2){
  animation-delay: 1.1s;
}
.about-female-cats-names {
  font-family: 'Oswald', sans-serif;
  display: table;
  text-align:center;
  width:100%;
  height:35%;
  font-size:30px;
}
.about-female-cats {
  font-family: 'Oswald', sans-serif;
  display: table;
  text-align:center;
  margin-left:10%;
  width:80%;
  height:65%;
  font-size:17;
}
.about-top-and-bot-cats-wrapper {
  display:flex;
  flex-direction:row;
  width:100%;
  height:200px;
	margin-bottom: 5px;
}
@keyframes animation {
  0%,
  100% {
    color: #d1a24d;
  }
  50% {
    color: #fff;
  }
}
@keyframes rotateBorder {
  to {
    --angle: 360deg;
  }
}
@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@media screen and (max-width: 1400px) and (min-width: 1000px) {
  .about-female-cats {
    font-size:14px;
  }
  .about-female-cats-names {
    font-size: 25px;
  }
}
@media screen and (max-width: 1200px) {
  .about-we-wrapper {
    float:left;
    /* font-size: calc(10vw); */
    font-family: 'Oswald', sans-serif;
    font-size: 150px;
    line-height: 170px;
    font-weight: bold;
    margin:0;
    margin-right: 20px;
    margin-bottom: 20px;
    color: #d1a24d;
    text-shadow: 3px 3px rgba(0,0,0,1);
    animation: animation linear 2s infinite;
    opacity: 1;
  }

  .about-content {
    font-family: 'Oswald', sans-serif;
    font-size: 15px;
    line-height: 25px;
    text-align: justify;
    word-wrap: break-word;
    color: #fff;
    transition: all 1s ease-out;
    opacity: 1;
  }
}


@media screen and (max-width: 1000px) {
  .about-image-text-wrapper {
    align-items: 'center';
    flex-direction: column;
  }
  .about-left-side-wrapper-before,
  .about-left-side-wrapper,
  .about-center-side-wrapper-before,
  .about-center-side-wrapper,
  .about-right-side-wrapper-before,
  .about-right-side-wrapper{
    margin-bottom:5%;
  }
  .about-top-and-bot-cats-wrapper {
    flex-direction:column;
    height:500px;
  }
  .about-right-cat-wrapper {
    margin:0;
  }

}
@media screen and (max-width: 800px) {
  .about-top-and-bot-cats-wrapper {
    flex-direction:column;
    height:400px;
  }
  .about-we-wrapper {
    font-size: 100px;
    line-height: 120px;
    margin-bottom: 0;
  }
  .about-right-side-image {
    background: url('../img/gallerybigptcs/wall.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .about-maincat-wrapper-image {
    width:100%;
    height:100%;
    background: url('../img/cat5small.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.4s ease;
    transform-origin: 100% 0%;
  }
  .about-female-cats {
    font-size:13px;
  }
  .about-female-cats-names {
    font-size: 25px;
  }
}
