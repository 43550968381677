.contact-wrapper {
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  background:black;
  width:100%;
  background-color: yellow;
  background-image: linear-gradient(to bottom right, #d1a24d, #fff);
}
.contact-message {
  width:80%;
  margin:5%;
  margin-bottom:1%;
  color:black;
  font-size:50px;
  text-align:center;
  font-family: 'Oswald', sans-serif;
}
.contact-logo {
  width:200px;
  height:200px;
  background: url('../img/logocontact.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin:2%;
}
.contact-headline {
  font-size:30px;
  margin:1%;
  color:black;
  font-family: 'Oswald', sans-serif;
}
.contact-phone-number, .contact-email, .contact-social-networks {
  flex-direction:row;
  width:80%;
  margin:0.5%;
  color:black;
  font-size:20px;
  text-align:center;
  font-family: 'Oswald', sans-serif;

}
.contact-phone-number a, .contact-email a, .contact-social-networks a {
  text-decoration: none;
  color:black;
}
.contact-copyright {
  width:60%;
  margin:2%;
  margin-Bottom:1%;
  border-top:1px solid black;
  color:black;
  font-size:15;
  line-height:5;
  text-align:center;
}
@media screen and (max-width: 1000px) {
  .contact-message {
    font-size: 35px;
  }
  .contact-headline {
    font-size: 25px;
  }
  .contact-copyright{
    width:100%
  }


}
