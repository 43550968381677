.message-wrapper {
  display: flex;
  position: absolute;
  flex-direction: column;
  margin-top: 20%;
  left:0;
  transition: all .5s ease-out;
}
.message-wrapper-before {
  display: flex;
  position: absolute;
  margin-top: 20%;
  left:-50%;
  flex-direction: column;
}
.title-wrapper {
  margin: auto;
  height: 50%;
	width: 90%;
	display: flex;
	align-items: center;
  flex:1;
  justify-content: center;
}
.text-wrapper {
  margin-top: 5%;
  height: 50%;
	width: 100%;
	display: flex;
	align-items: center;
  text-align: center;
  font-family: 'Oswald', sans-serif;
  text-shadow: 3px 3px rgba(0,0,0,1);
  font-weight: 600;
  flex:1;
  align-items: center;
  color:white;
  font-size:30px;
  display: flex;
  align-items: center;
  flex:1;
  justify-content: center;

}
.text-message {
  text-align: center;
  flex:1;
  color:white;
  font-size: calc(30px);
  font-weight: bold;
}
.title {
  text-align: center;
  float:left;
  font-size: 3.5vw;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  margin:0;
  color: #d1a24d;
  text-shadow: 3px 3px rgba(0,0,0,1);
  animation: animation linear 2s infinite;

}

.title:nth-child(1){
  animation-delay: 0.9s;
}
.title:nth-child(2){
  animation-delay: 1.1s;
}
.title:nth-child(3){
  animation-delay: 1.3s;
}
.title:nth-child(4){
  animation-delay: 1.5s;
  margin-right: 2%;
}
.title:nth-child(5){
  animation-delay: 1.7s;
}
.title:nth-child(6){
  animation-delay: 1.9s;
}
.title:nth-child(7){
  animation-delay: 2.1s;
}
.title:nth-child(8){
  animation-delay: 2.3s;
}
.title:nth-child(9){
  animation-delay: 2.5s;
}
.title:nth-child(10){
  animation-delay: 2.7s;
  margin-right: 2%;
}
.title:nth-child(11){
  animation-delay: 2.9s;
}
.title:nth-child(12){
  animation-delay: 3.1s;
}
.title:nth-child(13){
  animation-delay: 3.3s;
}
.title:nth-child(14){
  animation-delay: 3.5s;
}
.title:nth-child(15){
  animation-delay: 3.7s;
}
.title:nth-child(16){
  margin-right: 2%;

  animation-delay: 3.9s;
}
.title:nth-child(17){
  animation-delay: 4.1s;
}
.title:nth-child(18){
  animation-delay: 4.3s;
}
.title:nth-child(19){
  animation-delay: 4.5s;
}
.title:nth-child(20){
  animation-delay: 4.7s;
}
@keyframes animation {
  0%,
  100% {
    color: #d1a24d;
  }
  50% {
    color: #fff;
  }
}

@media screen and (max-width: 1400px) and (min-width:1000px) {
  .message-wrapper-before {
    margin-top:30%;
  }

  .message-wrapper {
    display: flex;
    position: absolute;
    flex-direction: column;
    margin-top: 30%;
    left:30%;
    transition: all .5s ease-out;
  }
  .title {
    font-size: 60px;
  }
}
@media screen and (max-width:999px) {
  .message-wrapper-before {
    margin-left: -150%;
  }

  .message-wrapper {
    display: flex;
    position: absolute;
    flex-direction: column;
    transition: all .5s ease-out;
    width:90%;
  }
  .title {
    font-size: 30px;
  }
  .text-wrapper {
    margin:auto;
    margin-top: 5%;
    height: 20%;
  	width: 90%;
  	display: flex;
  	align-items: center;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    text-shadow: 3px 3px rgba(0,0,0,1);
    font-weight: 600;
    flex:1;
    align-items: center;
    color:white;
    font-size:20px;
    display: flex;
    align-items: center;
    flex:1;
    justify-content: center;
  }
}
