.navigation {
  position:absolute;
  width: 100%;
  top:-28px;
}
::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;

}
::-webkit-scrollbar-thumb {
    background-color: #d1a24d;
    background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.starting-burger-hexagon {
  position: absolute;
	background: #d1a24d;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  transition: all 1.5s;
	width: 300px;
	height: 300px;
}
.starting-burger-hexagon-inside {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: black;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
}
.hex1-before{
  right:110%;
}
.hex2-before{
  top:-110px;
}
.hex3-before{
  right:-110%;
  top:500px;
}
.hex4-before{
  right: -700px;
}
.hex5-before{
  right:110%;
  top:500px;
}
.hex6-before{
  top:1300px;
}
.hex1 {
  right:calc(50% + 48px);
  top:0px;
	width: 100px;
	height: 100px;
}
.hex2 {
  right:calc(50% - 50px);
  top:0px;
	width: 100px;
	height: 100px;

}
.hex3 {
  right:calc(50% - 98px);
  top:73px;
	width: 100px;
	height: 100px;
}
.hex4 {
  right:calc(50% - 148px);
  top:0px;
	width: 100px;
	height: 100px;
}
.hex5 {
  right:calc(50%);
  top:73px;
	width: 100px;
	height: 100px;
}
.hex6 {
  right:calc(50% - 50px);
  top:146px;
	width: 100px;
	height: 100px;
}
.outside {
  position: absolute;
	background: #d1a24d;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  transition: all .5s ease-out;
  z-index: 10;


}
.burger-elements {
  background: linear-gradient(to bottom right,#d1a24d, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  flex:1;
  font-size:25;
  cursor: pointer;
  font-family: 'Oswald', sans-serif;

}
.inside {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background: black;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
}
.double-inside {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background: radial-gradient(#d1a24d, #8f4f2f );
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  text-align: center;
  vertical-align: middle;
  line-height: 88px;
  font-family: 'Oswald', sans-serif;
  color:white;
  text-shadow: 1px 1px rgba(0,0,0,1);
  letter-spacing: 1px;
}


.double-inside:hover{
  background: radial-gradient(#ddb176, #481915 );
}
@media screen and (max-width: 1400px) {
  .double-inside.pos2 {
    color:transparent;
    text-shadow: 1px 1px rgba(0,0,0,0);
    width:100%;
    height:100%;
    background: url('../img/logo.png');
    background-size: 50% 50%;
    background-position: 45% 50%;
    background-repeat: no-repeat;
  }

}
@media screen and (max-width: 1000px) {
  .outside.hex2{
    top:-30px;
  }
}
