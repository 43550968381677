.video-wrapper {
  align-items: center;
  width:100%;
  height:100%;
  display: flex;
   flex-direction: row;
}
.inner-video-wrapper {
  width:100%;
  height:100%;
  object-fit: cover;
}
.video-button {
  position: absolute;
  width:50px;
  height:50px;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  background-color: yellow;
  z-index: 50;
}
.video-item {
  height:60%;
  display: flex;
  margin:auto;
  flex:1;

}
.video-item-active {
  position:relative;
  height:80%;
  display: flex;
  margin:auto;
  flex:5;
  margin:2%;
  border:5px solid yellow;
  box-shadow: -5px -5px 15px 0px rgba(255,255,255,0.6);
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #d1a24d, #fff);
  --angle: 45deg;
  border-image: linear-gradient(var(--angle), #d1a24d, #fff) 1;
  transition: all .5s ease-out;


}

video::-webkit-media-controls-volume-slider {
  background-color: #d1a24d;
  padding-top: 0;
  margin-top: 20px;
  padding-bottom: 0;
}

video::-webkit-media-controls-current-time-display {
  color: #d1a24d;
}
video::-webkit-media-controls-current-time-display {
  color: #d1a24d;
}
.video-item {
  display: flex;
  margin:auto;
  flex:1;
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%); */
  transition: all 1s ease-out;
  margin:1%;
  border: 3px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #d1a24d, #fff);
  --angle: 45deg;
  border-image: linear-gradient(var(--angle), #d1a24d, #fff) 1;
}
.video-item-one-difference {
  height:75%;
}
.video-item-two-difference {
  height:70%;

}
.video-item-three-difference {
  height:65%;

}
.video-item-four-difference {
  height:60%;

}
.video-item-five-difference {
  height:55%;

}
.video-item-six-difference {
  height:50%;

}
.video-item-seven-difference {
  height:45%;

}
.video-item-eight-difference {
  height:40%;

}
.video-item-nine-difference {
  height:35%;

}
.video-item-ten-difference {
  height:30%;
}
.ai-outline-left {
  left:0;
}
.ai-outline-right {
  right:0;
}
.ai-outline-left,.ai-outline-right {
  transition:all 1s ease-out;
  position:absolute;
  top:40%;
  filter: drop-shadow(1px 1px rgba(0,0,0,1))
}

@media screen and (max-width: 1000px) {
  .video-item-active {
    flex:50
  }
}
