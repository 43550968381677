.App {
  background-color: black;
  width: 100%;
  background-repeat:no-repeat;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

}
#root {
  overflow-x: hidden;
}
body {
    max-width: 100%;
    overflow-x: hidden;
}
.starting-logo-hexagon {
  position: absolute;
	background: #d1a24d;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  transition: all 1s;
	width: 300px;
	height: 300px;
}
.starting-logo-hexagon-inside {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: black;
  -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
}
.logo {
  display : flex;
  background: url('img/logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  position:absolute;
  opacity: 0;
  transition: all 1s;
  z-index: 1000;
  height:100px;
  width:100px;

}
.logo-before {
  display : flex;
  background: url('img/logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  position:absolute;
  opacity: 1;
  transition: all 1s;
  z-index: 1000;
  height:100px;
  width:100px;

}
.logo-after {
  display : flex;
  background: url('img/logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  position:absolute;
  opacity: 1;
  transition: all 1s;
  z-index: 1000;
  height:100px;
  width:100px;

}
.logo-before {
  display : flex;
  background: url('img/logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  position:absolute;
  transition: all .5s ease-out;
  z-index: 1000;
  top:-100px;
}
.triangle-top {
  display : flex;
  flex-direction: column;
  background: url('img/triangletopdone.png');
  background-size: cover;
  background-repeat: no-repeat;
  position:absolute;
  transition: all .5s ease-out;
  overflow-x: hidden;
}
.rectangle {
  position:absolute;
  width:100%;
  height:50%;
  transition: all .5s ease-out;
  background-color: black;
  border-image: linear-gradient(to right,  #d1a24d, #fff ,#d1a24d, #fff,#d1a24d, #fff,#d1a24d, #fff) 1;
  display: flex;
}

.triangle-bottom {
  display : flex;
  flex-direction: column;
  background: url('img/trianglebotdone.png');
  background-size: cover;
  background-repeat: no-repeat;
  position:absolute;
  right:0;
  bottom:0;
  transition: all .5s ease-out;

}

.arrow-button-wrapper {
  position: absolute;
  bottom: 0;
  right: 5%;
  flex-direction: column;
  z-index: 10;

}
.loading-frame-text {
  height:50px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.loading-frame {
  position:relative;
  height:82px;
  width: 36px;
  margin: 0 auto;
  top:0;
  animation:bounce 1s infinite;
  transition: all .5s ease-out;
}
.loading-frame-after {
  position:relative;
  height:82px;
  width: 36px;
  margin: 0 auto;
  top:auto;
  animation:bounce 1s infinite;
  transition: all .5s ease-out;
}
.loading-frame-before {
  position:relative;
  height:82px;
  width: 36px;
  margin: 0 auto;
  top:-1000px;
}
.circle{
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #d1a24d;
  animation: animate linear 2s infinite;
}
ul {
    list-style-type: none;
}
.arrow-button-letters{
  animation: animateText linear 2s infinite;
  float:left; /* add this */
  font-size: 25px;
  font-family: 'Oswald', sans-serif;

}
.arrow-button-letters:nth-child(1){
  animation-delay: 3.9s;
}
.arrow-button-letters:nth-child(2){
  animation-delay: 4.1s;
}
.arrow-button-letters:nth-child(3){
  animation-delay: 4.3s;
}
.arrow-button-letters:nth-child(4){
  animation-delay: 4.5s;
}
.arrow-button-letters:nth-child(5){
  margin-right: 3px;
  animation-delay: 4.7s;
}
.arrow-button-letters:nth-child(6){
  animation-delay: 4.9s;
}
.arrow-button-letters:nth-child(7){
  animation-delay: 5.1s;
}
.arrow-button-letters:nth-child(8){
  margin-right: 3px;
  animation-delay: 5.3s;
}
.arrow-button-letters:nth-child(9){
  animation-delay: 5.5s;
}
.arrow-button-letters:nth-child(10){
  animation-delay: 5.7s;
}
.arrow-button-letters:nth-child(11){
  animation-delay: 5.9s;
}
.arrow-button-letters:nth-child(12){
  animation-delay: 6.1s;
}
.arrow-button-letters:nth-child(13){
  animation-delay: 6.3s;
}
.arrow-button-letters:nth-child(14){
  animation-delay: 6.5s;
}
.arrow-button-letters:nth-child(15){
  animation-delay: 6.7s;
}

.circle:nth-child(1) {
  animation-delay: 0.9s;
  transform: translate( -5px, 48px);

}
.circle:nth-child(2) {
  animation-delay: 1.1s;
  transform: translate(-10px , 60px);
}
.circle:nth-child(3) {
  animation-delay: 1.3s;
  transform: translate(-5px , 72px);
}
.circle:nth-child(4) {
  animation-delay: 1.1s;
  transform: translate( 2px , 60px);
}
.circle:nth-child(5) {
  animation-delay: 0.9s;
  transform: translate(8px , 48px);
}
.circle:nth-child(6) {
  animation-delay: 0.9s;
  transform: translate(-18px , 48px);
}
.circle:nth-child(7) {
  animation-delay: 0.7s;
  transform: translate(-5px , 36px);
}
.circle:nth-child(8) {
  animation-delay: 0.5s;
  transform: translate(-5px , 24px);
}
.circle:nth-child(9) {
  animation-delay: 0.3s;
  transform: translate(-5px , 12px);
}
.circle:nth-child(10) {
  animation-delay: 0.1s;
  transform: translate(-5px , 0px);
}
@keyframes animate {
  0%,
  100% {
    background-color: #d1a24d;
  }
  50% {
    background-color: #fff;
  }
}
@keyframes animateText {
  0%,
  100% {
    color: #d1a24d;
  }
  50% {
    color: #fff;
  }
}

@keyframes bounce {
0%       { bottom:5px; }
25%, 75% { bottom:15px; }
50%      { bottom:20px; }
100%     {bottom:0;}
}

@media screen and (max-width: 1400px) and (min-width: 1000px) {
  .triangle-top,.triangle-bottom {
    display: none;
  }
  .arrow-button-wrapper {
    padding-top: 5%;
    height:20%;
    position: absolute;
    bottom: 2%;
    right: 40%;
    flex-direction: column;
    z-index: 10;
  }
  .logo,.logo-after {
    display: none;
  }
  .rectangle {
    display:flex;
  }
}
@media screen and (max-width: 999px) {
  .triangle-top,.triangle-bottom {
    display: none;
  }
  .logo,.logo-after {
    display: none;
  }
  .rectangle {
    display:flex;
  }
  .arrow-button-wrapper {
    padding-top: 5%;
    position: absolute;
    bottom: 2%;
    right: 30%;
    width:40%;
    flex-direction: column;
    z-index: 10;
  }
  .arrow-button-letters {
    font-size: 20px;
  }
}
